.button {
  --button-height: 6.8rem;
  
  display: inline-block;
  height: var(--button-height);
  padding: 0 var(--button-height);
  line-height: calc(var(--button-height) - 4px);
  font-family: var(--font-text);
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  background-color: var(--black-blue);
  color: white;
  border: 2px solid var(--black-blue);
  border-radius: calc(var(--button-height) / 2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @include appearance(none);
  @include smoothed();
  
  &:hover {
    background-color: var(--main-blue);
  }
  
  &-small {
    --button-height: 5.6rem;
    
    font-size: 2rem;
    font-weight: 500;
  }
  
  &-wide {
    width: 100%;
  }

  &-white {
    background: white;
    color: var(--black-blue);
    border-color: white;
    
    &.button-outline {
      background: transparent;
      color: white;
      
      &:hover {
        background: white;
        color: var(--black-blue);
      }
    }
  }
  
  &-blue {
    background: var(--main-blue);
    color: white;
    border-color: var(--main-blue);
    
    &.button-outline {
      background: transparent;
      color: var(--main-blue);
      
      &:hover {
        background: var(--black-blue);
        color: white;
      }
    }
  }
  
  &-outline {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
  }
  
  &-more {
    position: relative;
    text-align: left;
    padding: 0 calc(var(--button-height) + 2rem) 0 2rem;
    
    &::after {
      content: '';
      position: absolute;
      right: 1rem;
      top: 50%;
      width: calc(var(--button-height) - 2rem);
      height: calc(var(--button-height) - 2rem);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 2.4rem auto;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out;
    }
    
    &.button-small {
      padding-right: calc(var(--button-height) + 1rem);
      
      &::after {
        background-size: 1.6rem auto;
      }
    }
    
    &.button-white {

      &::after {
        background-color: var(--black-blue);
        //background-image: url('../assets/btn-arrow-white.svg');
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMTAuNUMxLjE3MTU3IDEwLjUgMC41IDExLjE3MTYgMC41IDEyQzAuNSAxMi44Mjg0IDEuMTcxNTcgMTMuNSAyIDEzLjVWMTAuNVpNMjMuMDYwNyAxMy4wNjA3QzIzLjY0NjQgMTIuNDc0OSAyMy42NDY0IDExLjUyNTEgMjMuMDYwNyAxMC45MzkzTDEzLjUxNDcgMS4zOTM0QzEyLjkyODkgMC44MDc2MTIgMTEuOTc5MiAwLjgwNzYxMiAxMS4zOTM0IDEuMzkzNEMxMC44MDc2IDEuOTc5MTggMTAuODA3NiAyLjkyODkzIDExLjM5MzQgMy41MTQ3MkwxOS44Nzg3IDEyTDExLjM5MzQgMjAuNDg1M0MxMC44MDc2IDIxLjA3MTEgMTAuODA3NiAyMi4wMjA4IDExLjM5MzQgMjIuNjA2NkMxMS45NzkyIDIzLjE5MjQgMTIuOTI4OSAyMy4xOTI0IDEzLjUxNDcgMjIuNjA2NkwyMy4wNjA3IDEzLjA2MDdaTTIgMTMuNUwyMiAxMy41VjEwLjVMMiAxMC41VjEzLjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
      }
      
      &.button-outline {

        &::after {
          background-color: white;
          //background-image: url('../assets/btn-arrow-black.svg');
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMTAuNUMxLjE3MTU3IDEwLjUgMC41IDExLjE3MTYgMC41IDEyQzAuNSAxMi44Mjg0IDEuMTcxNTcgMTMuNSAyIDEzLjVWMTAuNVpNMjMuMDYwNyAxMy4wNjA3QzIzLjY0NjQgMTIuNDc0OSAyMy42NDY0IDExLjUyNTEgMjMuMDYwNyAxMC45MzkzTDEzLjUxNDcgMS4zOTM0QzEyLjkyODkgMC44MDc2MTEgMTEuOTc5MiAwLjgwNzYxMSAxMS4zOTM0IDEuMzkzNEMxMC44MDc2IDEuOTc5MTkgMTAuODA3NiAyLjkyODkzIDExLjM5MzQgMy41MTQ3MkwxOS44Nzg3IDEyTDExLjM5MzQgMjAuNDg1M0MxMC44MDc2IDIxLjA3MTEgMTAuODA3NiAyMi4wMjA4IDExLjM5MzQgMjIuNjA2NkMxMS45NzkyIDIzLjE5MjQgMTIuOTI4OSAyMy4xOTI0IDEzLjUxNDcgMjIuNjA2NkwyMy4wNjA3IDEzLjA2MDdaTTIgMTMuNUwyMiAxMy41VjEwLjVMMiAxMC41VjEzLjVaIiBmaWxsPSIjMTQxOTJEIi8+Cjwvc3ZnPgo=');
        }
        
        &:hover::after {
          right: .8rem;
          width: calc(var(--button-height) - 1.6rem);
          height: calc(var(--button-height) - 1.6rem);
          background-color: black;
          //background-image: url('../assets/btn-arrow-white.svg');
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMTAuNUMxLjE3MTU3IDEwLjUgMC41IDExLjE3MTYgMC41IDEyQzAuNSAxMi44Mjg0IDEuMTcxNTcgMTMuNSAyIDEzLjVWMTAuNVpNMjMuMDYwNyAxMy4wNjA3QzIzLjY0NjQgMTIuNDc0OSAyMy42NDY0IDExLjUyNTEgMjMuMDYwNyAxMC45MzkzTDEzLjUxNDcgMS4zOTM0QzEyLjkyODkgMC44MDc2MTIgMTEuOTc5MiAwLjgwNzYxMiAxMS4zOTM0IDEuMzkzNEMxMC44MDc2IDEuOTc5MTggMTAuODA3NiAyLjkyODkzIDExLjM5MzQgMy41MTQ3MkwxOS44Nzg3IDEyTDExLjM5MzQgMjAuNDg1M0MxMC44MDc2IDIxLjA3MTEgMTAuODA3NiAyMi4wMjA4IDExLjM5MzQgMjIuNjA2NkMxMS45NzkyIDIzLjE5MjQgMTIuOTI4OSAyMy4xOTI0IDEzLjUxNDcgMjIuNjA2NkwyMy4wNjA3IDEzLjA2MDdaTTIgMTMuNUwyMiAxMy41VjEwLjVMMiAxMC41VjEzLjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
        }
      }
    }
    
    &.button-blue {
      
      &::after {
        background-color: white;
        //background-image: url('../assets/btn-arrow-blue.svg');
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMTAuNUMxLjE3MTU3IDEwLjUgMC41IDExLjE3MTYgMC41IDEyQzAuNSAxMi44Mjg0IDEuMTcxNTcgMTMuNSAyIDEzLjVWMTAuNVpNMjMuMDYwNyAxMy4wNjA3QzIzLjY0NjQgMTIuNDc0OSAyMy42NDY0IDExLjUyNTEgMjMuMDYwNyAxMC45MzkzTDEzLjUxNDcgMS4zOTM0QzEyLjkyODkgMC44MDc2MTEgMTEuOTc5MiAwLjgwNzYxMSAxMS4zOTM0IDEuMzkzNEMxMC44MDc2IDEuOTc5MTkgMTAuODA3NiAyLjkyODkzIDExLjM5MzQgMy41MTQ3MkwxOS44Nzg3IDEyTDExLjM5MzQgMjAuNDg1M0MxMC44MDc2IDIxLjA3MTEgMTAuODA3NiAyMi4wMjA4IDExLjM5MzQgMjIuNjA2NkMxMS45NzkyIDIzLjE5MjQgMTIuOTI4OSAyMy4xOTI0IDEzLjUxNDcgMjIuNjA2NkwyMy4wNjA3IDEzLjA2MDdaTTIgMTMuNUwyMiAxMy41VjEwLjVMMiAxMC41VjEzLjVaIiBmaWxsPSIjMUQ1REFBIi8+Cjwvc3ZnPgo=');
      }
      
      &.button-outline {
        
        &::after {
          background-color: var(--main-blue);
          //background-image: url('../assets/btn-arrow-white.svg');
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMTAuNUMxLjE3MTU3IDEwLjUgMC41IDExLjE3MTYgMC41IDEyQzAuNSAxMi44Mjg0IDEuMTcxNTcgMTMuNSAyIDEzLjVWMTAuNVpNMjMuMDYwNyAxMy4wNjA3QzIzLjY0NjQgMTIuNDc0OSAyMy42NDY0IDExLjUyNTEgMjMuMDYwNyAxMC45MzkzTDEzLjUxNDcgMS4zOTM0QzEyLjkyODkgMC44MDc2MTIgMTEuOTc5MiAwLjgwNzYxMiAxMS4zOTM0IDEuMzkzNEMxMC44MDc2IDEuOTc5MTggMTAuODA3NiAyLjkyODkzIDExLjM5MzQgMy41MTQ3MkwxOS44Nzg3IDEyTDExLjM5MzQgMjAuNDg1M0MxMC44MDc2IDIxLjA3MTEgMTAuODA3NiAyMi4wMjA4IDExLjM5MzQgMjIuNjA2NkMxMS45NzkyIDIzLjE5MjQgMTIuOTI4OSAyMy4xOTI0IDEzLjUxNDcgMjIuNjA2NkwyMy4wNjA3IDEzLjA2MDdaTTIgMTMuNUwyMiAxMy41VjEwLjVMMiAxMC41VjEzLjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
        }
        
        &:hover::after {
          background-color: white;
          //background-image: url('../assets/btn-arrow-blue.svg');
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMTAuNUMxLjE3MTU3IDEwLjUgMC41IDExLjE3MTYgMC41IDEyQzAuNSAxMi44Mjg0IDEuMTcxNTcgMTMuNSAyIDEzLjVWMTAuNVpNMjMuMDYwNyAxMy4wNjA3QzIzLjY0NjQgMTIuNDc0OSAyMy42NDY0IDExLjUyNTEgMjMuMDYwNyAxMC45MzkzTDEzLjUxNDcgMS4zOTM0QzEyLjkyODkgMC44MDc2MTEgMTEuOTc5MiAwLjgwNzYxMSAxMS4zOTM0IDEuMzkzNEMxMC44MDc2IDEuOTc5MTkgMTAuODA3NiAyLjkyODkzIDExLjM5MzQgMy41MTQ3MkwxOS44Nzg3IDEyTDExLjM5MzQgMjAuNDg1M0MxMC44MDc2IDIxLjA3MTEgMTAuODA3NiAyMi4wMjA4IDExLjM5MzQgMjIuNjA2NkMxMS45NzkyIDIzLjE5MjQgMTIuOTI4OSAyMy4xOTI0IDEzLjUxNDcgMjIuNjA2NkwyMy4wNjA3IDEzLjA2MDdaTTIgMTMuNUwyMiAxMy41VjEwLjVMMiAxMC41VjEzLjVaIiBmaWxsPSIjMUQ1REFBIi8+Cjwvc3ZnPgo=');
        }
      }
    }
  }
}
