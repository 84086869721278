:root {
  // sizes
  --app-height: 100%;
  --header-height: 7.2rem;
  --header-hover-height: 7.2rem;
  --padding-x: 1rem;
  --section-padding-y: 5rem;
  --last-section-padding-y: 8rem;
  --max-width: 126.8rem;
  
  // fonts
  --font-text: 'Montserrat', sans-serif;

  // inputs
  --input-height: 5.4rem;
  
  // colors
  --black-blue: #202C4E;
  --main-blue: #1D5DAA;
  --graphite: #97A0B4;
  --light-graphite: #D0D4DD;
  --light-grey: #F6F6FA;
  --dark: #394569;
  
  --link-color: #3366CC;
  --link-color-hover: #202C4E;
  --table-border-color: #E1E3E5;
  --table-shadow-color: #DEDEE2;
  --splash-block-color: #202C4E;
  --splash-block-color-transparent: #202C4E99;
  --success-text: #106c3d;
  --success-bg: #bce1ce;

  @include mq('md') {
    --padding-x: 2rem;
  }
  
  @include mq('xl') {
    --header-height: 9.2rem;
    --header-hover-height: 11.8rem;
    --section-padding-y: 7rem;
    --last-section-padding-y: 10rem;
  }
}

$transition-hover: hover .25s ease-in-out;
