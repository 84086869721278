.fancybox__container {
  --fancybox-bg: rgb(255, 255, 255, 0.95);
}

.fancybox__slide.has-image > .fancybox__content {
  padding: 0;
  background-color: #fff;
  border-radius: 0px;
  box-shadow: 2px 2px 6px 2px rgba(255, 255, 255, 0.451);
}

.fancybox__content .f-button.is-close-btn::before, .fancybox__content .f-button.is-close-btn::after {
  content: "";
  position: absolute;
  right: 0;
  top: 9px;
  width: 22px;
  height: 4px;
  background: var(--dark);
}
