.section {
    padding: var(--section-padding-y) 0 var(--last-section-padding-y) 0;
    
    &:not(:last-child) {
      padding: var(--section-padding-y) 0;
    }
  
  .index & {
    --section-padding-y: 10rem;
  }
  
  &.background-blue,
  &.background-black-blue {
    color: white;
    
    a {
      color: white;
    }
  }
  
  &.accordion {
    padding: var(--accordion-padding-y) 0;
  }
  
  .breadcrumbs + & {
    padding-top: 0;
  }
  
  h1, .h1 {
    margin-bottom: 3rem;
  }
  
  h2, .h2 {
    margin-bottom: 2.4rem;
    
    &:not(:first-child) {
      margin-top: 5rem;
    }
  }
  
  h3, .h3 {
    margin-bottom: 2.4rem;
    
    &:not(:first-child) {
      margin-top: 5rem;
    }
  }
  
  ul {
    margin-top: 0;
    margin-bottom: 0;
    
    &:not(:last-child) {
      margin-bottom: 1.2em;
    }
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
}

.section-subscribe {
  padding: 10rem 0;
  
  h2, .h2 {
    font-size: 4.8rem;
    font-weight: 500;
  }
}

.section-news {
  
  background: rgba(29, 93, 170, 0.25);
  border-radius: 16px;
  margin-top: 30px;
  padding: 4rem;
  backdrop-filter: blur(1px);

  h2, .h2 {
    font-size: 4.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  a:link, a:visited {
    color: var(--light-graphite);
  }
  a:hover, a:active {
    color: #fff;
  }
}
