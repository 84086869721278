.accordion {
  --accordion-button-size: 2.8rem;
  --accordion-padding-y: 4.8rem;
  
  @include mq('lg') {
    --accordion-button-size: 4.6rem;
    --accordion-padding-y: 5.2rem;
  }
  
  &__toggler {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    
    > p, h1, .h1, h2, .h2, h3, .h3 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      
      .accordion--opened & {
        margin-bottom: 5rem !important;
      }
    }
  }
  
  &__content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease-out;
    
    .accordion--opened & {
      grid-template-rows: 1fr;
    }
  }
  
  &__container {
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
    overflow: hidden;
  }
  
  &__button {
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-block;
    width: var(--accordion-button-size);
    height: var(--accordion-button-size);
    margin: 6px 0 0 1.6rem;
    padding: 0;
    text-indent: -100vw;
    background: url('../assets/chevron-black.svg') center center no-repeat;
    background-size: contain;
    border: 0;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    @include appearance(none);
    @include smoothed();
    
    @include mq('lg') {
      margin-left: 3rem;
      margin-top: 0;
    }
    
    .accordion--opened & {
      transform: rotate(180deg);
    }
  }
}