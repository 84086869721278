.input {  
  height: var(--input-height);
  line-height: var(--input-height);
  font-size: 2rem;
  background: white;
  border: 1px solid var(--graphite);
  padding: 0 1rem;
  
  &::placeholder {
    color: var(--graphite);
  }
}

.input-text {
  display: block;
  box-sizing: border-box;
  padding: 0 1rem;
  height: var(--input-height);
  line-height: var(--input-height);
  font-size: 2rem;
  color: var(--graphite);
  background: white;
  border: 1px solid var(--graphite);
  border-radius: 8px;
  outline: none;
  appearance: none;
}
