.checkbox {
  display: block;
  height: 3rem;
  
  & > input[type="checkbox"] {
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: -1;
    opacity: 0;
    
    &:checked {
      
      & + span::before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjU2MjUgMi42NTMzNEwxMi4wNTYyIDEyLjU0NDhDMTEuNDcyOSAxMy4xNTE3IDEwLjUyNzEgMTMuMTUxNyA5Ljk0Mzc1IDEyLjU0NDhMMC40Mzc1MTMgMi42NTMzM0MtMC4xNDU4MzggMi4wNDYzNSAtMC4xNDU4MzggMS4wNjIyMyAwLjQzNzUxMyAwLjQ1NTIzOUMxLjAyMDg2IC0wLjE1MTc0OCAxLjk2NjY2IC0wLjE1MTc0OCAyLjU1MDAxIDAuNDU1MjM5TDExIDkuMzczMjhMMTkuNDUgMC40NTUyNEMyMC4wMzMzIC0wLjE1MTc0NyAyMC45NzkxIC0wLjE1MTc0NyAyMS41NjI1IDAuNDU1MjRDMjIuMTQ1OCAxLjA2MjIzIDIyLjE0NTggMi4wNDYzNSAyMS41NjI1IDIuNjUzMzRaIiBmaWxsPSIjMDBBREVGIi8+Cjwvc3ZnPgo=');
      }
    }
    
    &:disabled {
      
      & + span {
        cursor: auto;

        &::before {
          background-color: var(--table-border-color);
        }
      }
    }
  }
  
  & > span {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    
    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      flex-shrink: 0;
      flex-grow: 0;
      width: 3rem;
      height: 3rem;
      margin-right: 1.8rem;
      background-color: white;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 22px 13px;
      border: 1px solid var(--table-border-color);
    }
  }
}
