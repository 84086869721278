/**
  Customize included styles
 */
 
/** Bootstrap */
.g-3, .gx-3 {
  --bs-gutter-x: 3rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 5rem;
}

/** Fancybox */
.fancybox {
  
  &__container {
    --fancybox-bg: rgba(24, 24, 27, .5);
  }
  
  &__slide {
    
    &.has-image > .fancybox__content {
      padding: 2rem 3rem;
      background-color: white;
      border-radius: 20px;
      box-shadow: 2px 4px 4px 0px #00000073;
    }
  }
  
  &__content {

    .f-button,
    .f-button:hover {
      background: white;
    }
    
    .f-button.is-close-btn {
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 18px;
        height: 1px;
        background: var(--dark);
      }
      
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      svg {
        display: none;
      }
    }
  }
}