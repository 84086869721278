.breadcrumbs {
  padding: 2.8rem 0 3rem;
  font-size: 2rem;
  background-color: var(--light-grey);
  color: var(--graphite);
  
  @include mq('xl') {
    padding-top: calc(2.8rem + var(--header-hover-height) - var(--header-height));
  }
  
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  &__item {
    display: inline;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    
    &::after {
      content: '';
      display: inline-block;
      width: 1.1em;
      height: 1em;
      margin: 0 1em;
      background: url('../assets/arrow.svg') 50% 35% no-repeat;
      background-size: contain;
      vertical-align: middle;
    }
    
    &:last-child::after {
      display: none;
    }
  }
  
  a {
    text-decoration: none;
    color: var(--main-blue);
  }
}
