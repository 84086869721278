.table {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 2px var(--table-shadow-color);
  
  table {
    width: 100%;
    margin: 0;
    border-collapse: collapse;
  }
  
  th, td {
    text-align: left;
    vertical-align: top;
  }
  
  th {
    padding: 1.6rem;
    background-color: var(--main-blue);
    color: white;
  }
  
  td {
    padding: .7rem 1.6rem;
    border-bottom: 1px solid var(--table-border-color);
    transition: background-color .1s ease-in;
  }
  
  tr:hover td {
    background-color: var(--light-grey);
  }
}