/**
  В background.svg и background-lines.svg должен быть включен preserveAspectRatio="none"
  Figma при экспорте этого не делает!
 */
.splash {
  position: relative;
  padding: var(--header-height) var(--padding-x) 10rem;
  color: white;
  background: var(--black-blue) url('../assets/background.svg') top var(--header-height) left no-repeat;
  background-size: 100% 100%;
  
  @include mq('md') {
    background-position: top left;
  }
  
  @include mq('xl') {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    width: 100%;
    /*position: fixed;
    padding-bottom: 2.4rem; 
    min-height: 100vh;*/
  }
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: var(--header-height);
    right: 0;
    bottom: 0;
  }
  
  &::before {
    background: url('../assets/background-lines.svg') top 12rem left no-repeat;
    background-size: 100% 100%;
    
    @include mq('md') {
      background-position: top 13rem left;
    }
  }
  
  &::after {
    
    @include mq('md') {
      background: url('../assets/background-cubes.svg') right 6.5% top no-repeat;
      background-size: 70% auto;
      opacity: .8;
      mix-blend-mode: soft-light;
    }
    
    @include mq('lg') {
      background-position: right 6.5% top 3rem;
      background-size: 50% auto;
    }
    
    @include mq('xl') {
      background-size: 50% auto;
    }
  }
  
  > * {
    position: relative;
    z-index: 1;
  }
  
  &__container {
    padding: 7rem 0;
    
    @include mq('xl') {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      align-items: flex-start;
      max-width: 122rem;
      //padding: 0;
    }
  }
  
  &__header {
    margin: 0 0 2rem;
    font-size: 4.8rem;
    font-weight: 700;
    line-height: 1;
    color: white;
    
    @include mq('xl') {
      font-size: 9.6rem;
    }
    
    @include mq('xxxl') {
      font-size: 11rem;
      margin-bottom: 1rem;
    }
  }
  
  &__text {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.2;
    
    @include mq('xl') {
      font-size: 3.2rem;
    }
  }
  
  .button {
    margin-top: 5rem;
    
    @include mq('lg') {
      --button-height: 6.8rem;
      
      margin-top: 2rem;
      font-size: 2.4rem;
      font-weight: 600;
    }
    
    @include mq('xxl') {
      margin-top: 4rem;
    }
    
    @include mq('xxxl') {
      margin-top: 6rem;
    }
  }
}
