.news-list-item {
  width: 98%;
  margin-bottom: 5rem;

  @include mq('md') {
    width: 49%;
  }

  @include mq('xl') {
    width: 32%;
  }

  &__date, p {
    font-size: 1.8rem;
    line-height: 1.6;
    color: var(--dark);
  }

  &__date {
    color: var(--graphite);
  }

  &__text {
    margin-top: 0.4em;
    line-height: 1.4em;
  }

  &__header {
    margin: 1rem 0 !important;
    font-size: 2rem;
    line-height: 1.2em;
    color: var(--main-blue);
  }

  a {
    text-decoration: none;
  }
  a:hover {
    color: var(--black-blue);
  }
}


.news-list__row {
  padding: 3rem 0;
  @include mq('lg') {
    padding: 5rem 0;
  }
}

.news-list__row_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.news-list__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.news-list__tag {
  margin-bottom: 2rem;
  margin-right: 2rem;
  flex: 0 0 auto;
  display: flex;

  @include mq('lg') {
    margin-bottom: 0;
    margin-right: 3rem;
  }
}

.news-list__tag-counter {
  display: inline-block;
  background-color: var(--graphite);
  color: var(--light-grey);
  padding: 1rem;
}

.news-list__tag-counter--active {
  background-color: var(--black-blue);
  color: var(--light-grey);
}

.news-list__tag-link {
  display: inline-block;
  padding: 1rem 2rem;
  color: var(--black-blue);
  background-color: var(--light-graphite);
}

.news-list__tag-link--active {
  background-color: var(--main-blue);
  color: #fff;
}
.news-list__tag-link--active:hover {
  background-color: var(--black-blue);
  color: #fff;
}
