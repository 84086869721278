.popup {

  &__box {
    box-sizing: border-box;
    background-color: #fff;
    flex: 0 1 auto;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    padding: 10px;
    position: relative;
    width: 600px;
    border-radius: 16px;
    box-shadow: 2px 3px 10px #4B4B4D;

    @include mq('sm') {
      padding: 30px;
    }
  }

  &__box--login {
    max-width: 436px;
    padding: 0;
  }

  &__box--city {
    width: 1200px;
  }

  &__box--login > &__close {
    background-color: white;
    padding: 1px;
    right: 2px;
    top: 2px;
  }

  &__box--login > &__content {
    padding: 0 20px;

    @include mq('xl') {
      padding: 0 30px 30px;
    }
  }

  &__button {
    display: block;
    font-weight: 700;
    margin: 10px 0;
    width: 100%;

    @include mq('xl') {
      margin: 0;
    }
  }

  &__link {
    background-color: transparent;
    border-color: transparent;
    font-size: 18px;
    padding: 0;
    text-decoration: none;
    width: auto;
  }

  &__link--comment {
    font-size: 14px;
  }

  &__close {
    background-color: transparent;
    border: 0;
    //color: var(--dark);
    //cursor: pointer;
    height: 16px;
    opacity: 0.3;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 22px;
    height: 22px;

    @include mq('xl') {
      right: 30px;
      top: 30px;
    }

    &::before,
    &::after {
      height: 3px;
    }
  }

  &__close:hover {
    opacity: 1;
  }

  &__comment {
    color: var(--graphite);
    font-size: 14px;
    margin: 0;
  }

  &__comment--with-margin {
    margin-top: 20px !important;
  }

  &__content {
    background: var(--light-grey);
    color: var(--graphite);
    font-size: 14px;
    line-height: 1.25;
    border-radius: 12px;

    @include mq('md') {
      font-size: 18px;
    }
  }

  &__field {
    font-size: 14px;
    line-height: 16px;
    padding-left: 0;
    width: 100%;
    border-width: 0 0 1px 0;
  }

  &__form-group {
    margin-bottom: 24px;
    width: 100%;
  }

  &__form-group--stretch {
    flex: 1;
  }

  &__header {
    display: block;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 20px;
    padding-right: 30px;
  }

  &__link {
    color: var(--link-color);
  }

  &__text {
    margin-bottom: 10px;
  }

  &__wrap {
    align-items: center;
    background: rgba(0, 0, 0, 0.55);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: $transition-hover;
    visibility: hidden;
    width: 100%;
    z-index: 10000;
  }

  &__wrap--open {
    opacity: 1;
    visibility: visible;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__row--gaped {
    gap: 1rem;
  }

  &__row--center {
    align-items: center;
  }

  &__row--border {
    border-top: 1px solid var(--table-border-color);
    padding-top: 24px;
  }

  &__column {
    flex: 0 0 100%;
    max-width: 100%;

    @include mq('xl') {
      display: flex;
      flex: 0 0 50%;
      flex-direction: column;
      max-width: 50%;
    }
  }

  &__column:nth-child(2n) {

    @include mq('xl') {
      padding-left: 15px;
    }
  }

  &__column:nth-child(2n + 1) {

    @include mq('xl') {
      padding-right: 15px;
    }
  }

  &__tabs {
    display: flex;
    justify-content: stretch;
    margin-bottom: 15px;
    width: 100%;
  }

  &__tab {
    background-color: var(--main-blue);
    border: 1px solid var(--main-blue);
    border-radius: 0;
    color: white;
    cursor: pointer;
    flex: 0 0 50%;
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    max-width: 50%;
    outline: none;
    padding: 15px 10px;
    transition: background-color var(--graphite), color var(--graphite);

    @include mq('md') {
      font-size: 18px;
      padding: 20px;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      border-color: transparent;
      color: var(--graphite);
    }

    &--active {
      background-color: transparent;
      border-color: transparent;
      color: var(--graphite);
    }
  }
}
.success {
  padding: 20px 10px;
  background-color: var(--success-bg);
  border-radius: 12px;
  color: var(--success-text);
}
