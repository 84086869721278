.pagination__item {
  flex: 0 0 auto;
  margin:0 5px 0 0;
  line-height: 1;
}

.pagination__item--last {
  border-right: none;
}

.pagination__item--disabled {
  color: var(--graphite);
}

.pagination__item-element {
  display: block;
  line-height: 1;
  text-align: center;
  height: 44px;
  font-size: 24px;
  padding: 10px 20px;
  color: var(--main-blue);
}

.pagination__item-element--active {
  color: #fff;
  background-color: var(--main-blue);
  border-radius: 8px;
}

.pagination__item-element--flip {
  transform: rotate(180deg);
}

.pagination__more {
  margin: 15px 0;
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    //display: none !important;
  }
}

.pagination__paginator {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 40px 0;
}

.pagination__wrap {
  //display: none;

  @media (min-width: 768px) {
    display: block;
    width: 100%;
  }
}

#pagination {
  width: 100%;
}
