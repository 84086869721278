.social {
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
  list-style: none;
  
  &__item {
    margin: 0;
    padding: 0;
  }
  
  .icon {
    width: 3.2rem;
    height: 3.2rem;
  }
}