.download-block {
  padding-bottom: 12px;
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: .03em;
  
  &__container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: var(--black-blue);
    background-color: var(--light-grey);
    border-radius: 16px;
    transition: all .2s;
    height: 100%;
    
    &:hover {
      color: white;
      background-color: var(--main-blue);
      box-shadow: 0 4px 8px rgba(0,0,0,.2); 
    }
  }
  
  &__row {
    display: flex;
    
    & + & {
      margin-top: 2rem;
    }
  }
  
  &__header {
    padding-right: 2.6rem;
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: .03em;
    //background-image: url('../assets/download-icon.svg');
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNTg1MDcgMC44NjgyOTVDMS45OTkyOSAwLjI4MjUwOCAxLjA0OTU0IDAuMjgyNTA4IDAuNDYzNzU0IDAuODY4Mjk1Qy0wLjEyMjAzMyAxLjQ1NDA4IC0wLjEyMjAzMyAyLjQwMzgzIDAuNDYzNzU0IDIuOTg5NjJMMi41ODUwNyAwLjg2ODI5NVpNMTUuNjY2NSAxNy41NzExQzE2LjQ5NSAxNy41NzExIDE3LjE2NjUgMTYuODk5NSAxNy4xNjY1IDE2LjA3MTFMMTcuMTY2NSAyLjU3MTA5QzE3LjE2NjUgMS43NDI2NiAxNi40OTUgMS4wNzEwOSAxNS42NjY1IDEuMDcxMDlDMTQuODM4MSAxLjA3MTA5IDE0LjE2NjUgMS43NDI2NiAxNC4xNjY1IDIuNTcxMDlWMTQuNTcxMUgyLjE2NjU1QzEuMzM4MTIgMTQuNTcxMSAwLjY2NjU1IDE1LjI0MjcgMC42NjY1NSAxNi4wNzExQzAuNjY2NTUgMTYuODk5NSAxLjMzODEyIDE3LjU3MTEgMi4xNjY1NSAxNy41NzExTDE1LjY2NjUgMTcuNTcxMVpNMC40NjM3NTQgMi45ODk2MkwxNC42MDU5IDE3LjEzMThMMTYuNzI3MiAxNS4wMTA0TDIuNTg1MDcgMC44NjgyOTVMMC40NjM3NTQgMi45ODk2MloiIGZpbGw9IiMxNDE5MkQiLz4KPC9zdmc+Cg==');
    background-position: right top 1rem;
    background-repeat: no-repeat;
    
    a:hover & {
      //background-image: url('../assets/download-icon-white.svg');
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNTg1MDcgMC44NjgyOTVDMS45OTkyOSAwLjI4MjUwOCAxLjA0OTU0IDAuMjgyNTA4IDAuNDYzNzU0IDAuODY4Mjk1Qy0wLjEyMjAzMyAxLjQ1NDA4IC0wLjEyMjAzMyAyLjQwMzgzIDAuNDYzNzU0IDIuOTg5NjJMMi41ODUwNyAwLjg2ODI5NVpNMTUuNjY2NSAxNy41NzExQzE2LjQ5NSAxNy41NzExIDE3LjE2NjUgMTYuODk5NSAxNy4xNjY1IDE2LjA3MTFMMTcuMTY2NSAyLjU3MTA5QzE3LjE2NjUgMS43NDI2NiAxNi40OTUgMS4wNzEwOSAxNS42NjY1IDEuMDcxMDlDMTQuODM4MSAxLjA3MTA5IDE0LjE2NjUgMS43NDI2NiAxNC4xNjY1IDIuNTcxMDlWMTQuNTcxMUgyLjE2NjU1QzEuMzM4MTIgMTQuNTcxMSAwLjY2NjU1IDE1LjI0MjcgMC42NjY1NSAxNi4wNzExQzAuNjY2NTUgMTYuODk5NSAxLjMzODEyIDE3LjU3MTEgMi4xNjY1NSAxNy41NzExTDE1LjY2NjUgMTcuNTcxMVpNMC40NjM3NTQgMi45ODk2MkwxNC42MDU5IDE3LjEzMThMMTYuNzI3MiAxNS4wMTA0TDIuNTg1MDcgMC44NjgyOTVMMC40NjM3NTQgMi45ODk2MloiIGZpbGw9IiNGRkZGRkYiLz4KPC9zdmc+Cg==');
    }
  }
  
  &__small {
    font-size: 1.2rem;
    line-height: 1.2;
    letter-spacing: .03em;
    color: var(--dark);
    
    a:hover & {
      color: white;
    }
  }
  
  &__icon {
    width: 3.6rem;
    height: 3.6rem;
    margin-right: 1.3rem;
  }
  
  .col & {
    height: 100%;
  }
  
  a {
    text-decoration: none;
  }
}
