.splash-block {
  --splash-block-padding: 2rem;
  
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 3rem;
  position: relative;
  height: 100%;
  padding: var(--splash-block-padding);
  font-size: 1.8rem;
  line-height: 1.4;
  text-decoration: none;
  color: white;
  background-color: var(--splash-block-color);
  border-radius: 16px;
  box-shadow: 0 3px 3px #3366CC78;
  transition: all .1s ease-out;
  
  @include mq('xxl') {
    height: 100%;
    padding-left: calc(13rem + var(--splash-block-padding));
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: var(--splash-block-color-transparent);
    box-shadow: none;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
  }
  
  &::after {
    
    @include mq('xl') {
      content: '';
      position: absolute;
      top: var(--splash-block-padding);
      right: var(--splash-block-padding);
      width: 2.8rem;
      height: 5rem;
      //background-image: url('../assets/download-icon-white.svg'); 
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuNTg1MDcgMC44NjgyOTVDMS45OTkyOSAwLjI4MjUwOCAxLjA0OTU0IDAuMjgyNTA4IDAuNDYzNzU0IDAuODY4Mjk1Qy0wLjEyMjAzMyAxLjQ1NDA4IC0wLjEyMjAzMyAyLjQwMzgzIDAuNDYzNzU0IDIuOTg5NjJMMi41ODUwNyAwLjg2ODI5NVpNMTUuNjY2NSAxNy41NzExQzE2LjQ5NSAxNy41NzExIDE3LjE2NjUgMTYuODk5NSAxNy4xNjY1IDE2LjA3MTFMMTcuMTY2NSAyLjU3MTA5QzE3LjE2NjUgMS43NDI2NiAxNi40OTUgMS4wNzEwOSAxNS42NjY1IDEuMDcxMDlDMTQuODM4MSAxLjA3MTA5IDE0LjE2NjUgMS43NDI2NiAxNC4xNjY1IDIuNTcxMDlWMTQuNTcxMUgyLjE2NjU1QzEuMzM4MTIgMTQuNTcxMSAwLjY2NjU1IDE1LjI0MjcgMC42NjY1NSAxNi4wNzExQzAuNjY2NTUgMTYuODk5NSAxLjMzODEyIDE3LjU3MTEgMi4xNjY1NSAxNy41NzExTDE1LjY2NjUgMTcuNTcxMVpNMC40NjM3NTQgMi45ODk2MkwxNC42MDU5IDE3LjEzMThMMTYuNzI3MiAxNS4wMTA0TDIuNTg1MDcgMC44NjgyOTVMMC40NjM3NTQgMi45ODk2MloiIGZpbGw9IiNGRkZGRkYiLz4KPC9zdmc+Cg=='); 
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 2rem;
      opacity: 0;
      transition: opacity .1s ease-out;
    }
    
    @include mq('xxl') {
      width: 3.2rem;
      height: 3.2rem;
      background-size: 2.8rem;
    }
  }
  
  &:hover {
    color: white;
    box-shadow: 0 3px 3px #3366CC78;
    
    @include mq('xl') {
      background-color: var(--splash-block-color);
    }
    
    &::after {
      
      @include mq('xl') {
        opacity: 1;
      }
    }
  }
  
  &__container {
    display: flex;
    flex-direction: column;
    
  }
  
  &__icon {
    width: 5rem;
    height: auto;
    
    @include mq('xxl') {
      display: flex;
      align-items: center;
      position: absolute;
      left: var(--splash-block-padding);
      top: var(--splash-block-padding);
      bottom: var(--splash-block-padding);
      width: 10rem;
      opacity: .7;
      transition: opacity .1s ease-out;
    }
    
    img {
      width: 100%;
      height: auto;
    }
    
    a:hover & {
      opacity: 1;
    }
  }
  
  &__title {
    align-self: center;
    font-size: 2.4rem;
    line-height: 1.08;
    letter-spacing: .03em;
    
    @include mq('xl') {
      padding-right: 2.8rem;
    }
    
    @include mq('xxl') {
      padding-right: 3.2rem;
    }
  }
}
