.news-item {
  @include smoothed();
  
  &__date, p {
    font-size: 1.6rem;
    line-height: 1.6;
  }
  
  &__date {
    color: var(--graphite);
  }
  
  &__header {
    margin: 1rem 0 !important;
    font-size: 2rem;
    line-height: 1.2;
  }
  
  a {
    text-decoration: none;
  }
}