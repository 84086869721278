.subscribe-form {
  
  .input {
    border-radius: calc(var(--input-height) / 2);
  }
  
  .button {
    --button-height: 5.4rem;
    
    padding: 0 2rem;
    font-size: 2rem;
    font-weight: 400;
    
    @include mq('sm') {
      padding: 0 var(--button-height);
    }
    
    @include mq('xl') {
      font-size: 2.4rem;
      font-weight: 600;
    }
  }
  
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  legend {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1em;
  }
  
  .group {
    
    .input {
      flex-grow: 1;
      flex-shrink: 1;
      padding-right: calc(var(--input-height) + 2rem);
      margin-right: calc(-1 * var(--input-height));
      width: 50%;
    }
  }
}
