.close-button {
  position: relative;
  box-sizing: border-box;
  width: 64px;
  height: 80px;
  padding: 0;
  background: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  @include appearance(none);
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 4px;
    left: 50%;
    top: 50%;
    background: var(--dark);
  }
  
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
