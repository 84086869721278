@import "../../../node_modules/mmenu-light/src/mmenu-light.scss";

:root {
  --mm-spn-item-height: 44px;
  --mm-spn-item-indent: 20px;
  --mm-spn-items-gap: 20px;
  --mm-ocd-width: 100%;
}

/** 
  Меню в шапке
 */
.menu {
  display: none;
  
  @include mq('lg') {
    display: block;
    font-size: 1.8rem;
    font-weight: 400;
    @include smoothed;
  }
  
  @include mq('xl') {
    font-size: 2rem;
  }
  
  // 1st level
  & > ul {
    display: flex;
    gap: 2rem;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    
    @include mq('xl') {
      gap: 4rem;
    }
    
    & > li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      margin: 0;
      padding: 0;
      height: 100%;
      
      &:hover {

        & > a {
          color: var(--main-blue);
          
          .header--transparent & {
            color: var(--graphite);
          }
        }
        
        // 2nd level visible
        ul {
          margin-top: -1rem;
          transform: translate(0, 0);
          opacity: 1;
        }
      }
      
      & > a {
        height: 100%;
        line-height: var(--header-height);
        padding: 0 1rem;
        
        .header__container:hover & {
          line-height: var(--header-hover-height);
        }
      }
    } 
    
    // 2nd level
    ul {
      position: absolute;
      left: -1rem;
      top: var(--header-hover-height);
      margin: -4px 0 0;
      padding: 0 2rem 2rem;
      background: rgba(255, 255, 255, .9);
      list-style-type: none;
      border-radius: 16px;
      z-index: -1;
      opacity: 0;
      transform: translate(0, -100%);
      transition: .2s all ease-in;
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
      
      .header--transparent & {
        padding-top: 2rem;
        background: var(--splash-block-color-transparent);
      }
      
      a {
        padding: 6px 0;
      }
    }
  }
  
  a {
    display: block;
    text-decoration: none;
    white-space: nowrap;
    color: black;
    transition: $transition-hover;
    
    .header--transparent & {
      color: white;
    }
    
    &:hover {
      color: var(--main-blue);
      
      .header--transparent & {
        color: var(--graphite);
      }
    }
  }
}

/** 
  Переопределенные классы mmenu-light
 */
.mm-spn {
  font-family: var(--font-text);
  font-size: 2rem;
  font-weight: normal;
  @include smoothed;
  
  &.mm-spn--light {
    background: var(--black-blue);
    color: white;
  }
  
  &.mm-spn--navbar {

    // ссылка Назад (шеврон)
    &::before {
      top: calc(var(--header-height) + var(--mm-spn-items-gap));
      left: calc(var(--mm-spn-item-indent) / 2);
      width: var(--mm-spn-item-height);
      height: var(--mm-spn-item-height);
      margin: 0;
      //background-image: url('../../assets/chevron-white.svg');
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjU4MDQgMTIuMDU2OUMxMi4xMzk5IDExLjQ3MzQgMTIuMTM5OSAxMC41Mjc2IDExLjU4MDQgOS45NDQxMUwyLjQ0OTUgMC40Mzc2MzVDMS44ODg4OCAtMC4xNDU4NzkgMC45ODAyNDkgLTAuMTQ1ODc5IDAuNDE5NjI0IDAuNDM3NjM1Qy0wLjEzOTg3NSAxLjAyMTE1IC0wLjEzOTg3NSAxLjk2NjkyIDAuNDE5NjI0IDIuNTUwMDZMOC41MzY1IDExLjAwMDFMMC40MTk2MjMgMTkuNDUwMkMtMC4xMzk4NzYgMjAuMDMzNyAtMC4xMzk4NzYgMjAuOTc5NSAwLjQxOTYyMyAyMS41NjI2QzAuOTgwMjQ4IDIyLjE0NTggMS44ODg4NyAyMi4xNDU4IDIuNDQ5NSAyMS41NjI2TDExLjU4MDQgMTIuMDU2MkwxMS41ODA0IDEyLjA1NjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
      background-position: center center;
      background-repeat: no-repeat;
      border: 0;
      opacity: 1;
      transform: rotate(180deg);
    }
    
    // заголовок
    &::after {
      top: calc(var(--header-height) + var(--mm-spn-items-gap));
      padding: 0 var(--mm-spn-item-indent) 0 calc(var(--mm-spn-item-height) + var(--mm-spn-item-indent));
      opacity: 1;
    }
    
    // заголовок первого уровня
    &.mm-spn--main::after {
      display: none;
    }
    
    // меню
    ul {
      top: calc(var(--header-height) + var(--mm-spn-item-height) + var(--mm-spn-items-gap));
    }
    
    &.mm-spn--main ul {
      top: var(--header-height);
    }
  }
  
  li::after,
  &.mm-spn--navbar ul::before,
  a:not(:last-child)::after {
    border: 0;
  }
  
  ul {
    
    &::after {
      display: none;
    }
  }
  
  li {
    margin: var(--mm-spn-items-gap) 0;
    
    &::before {
      width: 11px;
      height: 18px;
      //background-image: url('../../assets/chevron-white.svg');
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjU4MDQgMTIuMDU2OUMxMi4xMzk5IDExLjQ3MzQgMTIuMTM5OSAxMC41Mjc2IDExLjU4MDQgOS45NDQxMUwyLjQ0OTUgMC40Mzc2MzVDMS44ODg4OCAtMC4xNDU4NzkgMC45ODAyNDkgLTAuMTQ1ODc5IDAuNDE5NjI0IDAuNDM3NjM1Qy0wLjEzOTg3NSAxLjAyMTE1IC0wLjEzOTg3NSAxLjk2NjkyIDAuNDE5NjI0IDIuNTUwMDZMOC41MzY1IDExLjAwMDFMMC40MTk2MjMgMTkuNDUwMkMtMC4xMzk4NzYgMjAuMDMzNyAtMC4xMzk4NzYgMjAuOTc5NSAwLjQxOTYyMyAyMS41NjI2QzAuOTgwMjQ4IDIyLjE0NTggMS44ODg4NyAyMi4xNDU4IDIuNDQ5NSAyMS41NjI2TDExLjU4MDQgMTIuMDU2MkwxMS41ODA0IDEyLjA1NjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      border: 0;
      opacity: 1;
      transform: translate(50%, -50%);
    }
    
    &::after {
      margin-left: 0;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
  Меню в подвале
 */
.menu-footer {
  display: flex;
  gap: 3rem;
  margin: 0;
  padding: 0;
  list-style: none;
  
  li {
    margin: 0;
    padding: 0;
  }
  
  // 1 уровень
  > li {
    flex: 1 1 0;
    font-size: 2.4rem;
    line-height: 1.2;
  }
  
  // 2 уровень
  ul {
    margin: 2rem 0 0;
    padding: 0;
    list-style: none;
    
    > li {
      font-size: 1.8rem;
      line-height: 1.6;
      
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
  
  a {
    color: white;
    text-decoration: none;
  }
}

/** 
  Мобильное меню
 */
.mmenu {
  background-color: var(--black-blue);
  
  @include mq('lg') {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: var(--header-height);
    padding: 0 1rem;
    
    a {
      padding: 0;
    }
  }
  
  &__logo {
    width: 20rem;
    height: 5.2rem;
  }
  
  .close-button {
    display: block;
    position: relative;
    width: 4.2rem;
    height: 4.2rem;
    padding: 0;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      left: 50%;
      background: white;
    }
    
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

/** 
  Бургер
 */
.menu-button {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  width: 4.2rem;
  height: 4.2rem;
  
  @include mq('lg') {
    display: none;
  }
  
  &::before,
  &::after,
  span {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 2px;
    background: var(--black-blue);
    
    .header--transparent & {
      background: white;
    }
  }
    
  &::before {
    margin-top: 4px;
  }
    
  &::after {
    margin-top: -4px;
  }
}