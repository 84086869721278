.background {
  
  &-white {
    background-color: white;
  }
  
  &-grey {
    background-color: var(--light-grey);
  }
  
  &-blue {
    background-color: var(--main-blue);
  }
  
  &-black-blue {
    background-color: var(--black-blue);
  }
}