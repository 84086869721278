.footer {
  padding: 10rem 0;
  font-size: 1.8rem;
  line-height: 1.6;
  background-color: var(--black-blue);
  color: var(--graphite);
  @include smoothed();
  
  &__logo {
    display: none;
    
    @include mq('lg') {
      display: block;
      width: 28.9rem;
      height: 7.5rem;
      margin-bottom: 5rem;
    }
  }
  
  &__privacy {
    display: flex;
    margin: 0 0 3rem;
    padding: 0;
    list-style: none;
    
    li {
      position: relative;
      margin: 0;
      padding: 0 1.8em;
      
      &::after {
        content: '|';
        position: absolute;
        right: 0;
      }
      
      &:first-child {
        padding-left: 0;
      }
      
      &:last-child {
        padding-right: 0;
        
        &::after {
          display: none;
        }
      }
    }
  }
  
  .social {
    margin-bottom: 3rem;
  }
  
  p {
    font-size: 1.8rem;
    
    &.footer__contacts {
      font-size: 2.4rem;
      line-height: 2;
    }
  }
  
  a {
    color: var(--graphite);
    text-decoration: underline;
  }
}