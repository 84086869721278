html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html {
  font-size: 10px;
  -webkit-text-size-adjust: 100%;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding-top: var(--header-height);
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-family: var(--font-text);
  font-size: 1.6rem;
  color: var(--dark);
  background: white;
  overflow-x: hidden;
  
  &.index {
    padding-top: 0;
    
/*
    @include mq('xl') {
      overflow: hidden;
    }
*/
  }
}

h1, .h1 {
  margin: 0;
  font-size: 3.6rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--black-blue);
  
  @include mq('xl') {
    font-size: 6.7rem;
  }
}

h2, .h2 {
  margin: 0;
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 1.2;
  
  @include mq('xl') {
    font-size: 4rem;
  }
}

h3, .h3 {
  margin: 0;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.2;
  
  @include mq('xl') {
    font-size: 3.2rem;
  }
}

ul {
  padding-left: 1.5em;

  li {
    margin-top: 0.5em;
    line-height: 1.4;
  }

  &.no-bullet {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    
    li {
      margin-left: 0;
      padding-left: 0;
    }
    
    li + li {
      margin-top: 1em;
    }
  }
}

p, ol, ul, li, td {
  font-size: 2rem;
  line-height: 1.6;
}

p {
  margin: 0;
  
  &:not(:first-child) {
    margin-top: 1.2em;
  }
}

th {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.2;
}

img {
  vertical-align: top;
  
  a > & {
    border: 0;
  }
}

a {
  color: var(--link-color);
  
  &:hover,
  &.active {
    color: var(--link-color-hover);
  }
}

.outlink {
  background-image: url('../assets/outlink.svg');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-right: 22px;
}

b {
  font-weight: 500;
}

strong {
  font-weight: 600;
}

.nobr {
  white-space: nowrap;
}

.wordbreak {
  word-break: break-word;
}

.hidden {
  display: none !important;
}

.is-locked {
  overflow: hidden;
}

.scrolled-h {
  overflow-x: auto;
}

.text-icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin-bottom: -1px;
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}
