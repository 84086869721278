.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,.15);
  z-index: 100;
  transition: background .15s ease-out;
  
  &.header--transparent {
    --header-hover-height: var(--header-height);
    
    background: var(--black-blue);
    box-shadow: none;
    
    @include mq('md') {
      background: transparent;
    }
  }
  
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    padding: 0 1rem;
    transition: height .3s;
    
    @include mq('xl') {
      padding: 0 2rem;
    }
    
    &:hover {
      
      @include mq('xl') {
        height: var(--header-height);
      }
    }
  }
  
  &__logo {
    width: 20rem;
    height: 5.2rem;
    
    @include mq('xl') {
      width: 23.8rem;
      height: 6.1rem;
    }
    
    .logo__sign,
    .logo__text,
    .logo__text_white {
      display: none;
      
      @include mq('xl') {
        display: block;
        transition: all .3s;
      }
    }
    
    .logo__text {
      //opacity: 0;
      
      .header:hover & {
        opacity: 1;
      }
      
      .header--transparent & {

        @include mq('xl') {
          display: none;
        }
      }
    }
    
    .logo__text_white {
      
      .header--transparent & {

        @include mq('xl') {
          display: block;
        }
      }
    }
    
    .logo__m {
      width: 100%;
      height: 100%;
      
      @include mq('xl') {
        display: none;
      }
      
      .header--transparent & {
        display: none;
      }
    }
    
    .logo__m_white {
      display: none;
      
      @include mq('xl') {
        display: none !important;
      }
      
      .header--transparent & {
        display: block;
      }
    }
  }
}
