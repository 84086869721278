.form {
  padding: 2rem var(--padding-x);

  @include mq('lg') {
    padding: 3rem;
  }

  &__row {

    @include mq('md') {
      display: flex;
    }

    & + & {
      margin-top: 2rem;
    }
  }

  &__col {

    @include mq('md') {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
    }

    & > .form__row {

      @include mq('md') {
        flex-grow: 1;
      }
    }

    & + & {
      margin-top: 2rem;

      @include mq('md') {
        margin-top: 0;
        margin-left: 1rem;
      }

      @include mq('lg') {
        margin-left: 3rem;
      }
    }
  }

  &__input {
    width: 100%;

    @include mq('md') {
      display: flex;
      flex-direction: column;
    }

    & input, textarea, label {
      width: 100%;
    }

    textarea {
      flex-grow: 1;
    }
  }

  &__label {
    display: block;
    margin: 0 0 1rem;
    font-size: 1.6rem;
    line-height: 1.75;
    font-weight: 500;
    color: var(--black-blue);
  }

  &__comment {
    margin-top: 3rem;
    font-size: 1.4rem;
    line-height: 1.57;
    color: var(--graphite);

    @include mq('md') {
      margin-top: 0;
    }
  }

  &__buttons {
    margin-top: 3rem;

    .button {
      width: 100%;

      @include mq('md') {
        width: auto !important;
        padding: 0 5.3rem;
      }
    }
  }
}

.has-error input.input-text {
  border: 1px solid #DC1D00;
}

.has-success input.input-text {
  border: 1px solid green;
}

.fv-plugins-message-container {
  margin-top: 1rem;
  color: #DC1D00;
  font-size: 1.2rem
}

.uwaga {
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: -999px;
  left: -999px;
}
