.color {
  
  &-graphite {
    color: var(--graphite);
  }
  
  &-black-blue {
    color: var(--black-blue);
  }
  
  &-main-blue {
    color: var(--main-blue);
  }
  
  &-light-graphite {
    color: var(--light-graphite);
  }
  
  &-light-grey {
    color: var(--light-grey);
  }
  
  &-dark {
    color: var(--dark);
  }  
}