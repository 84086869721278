$breakpoints: (
  "xs":   0,
  "sm":   576px,
  "md":   768px,
  "lg":   992px,
  "xl":   1200px,
  "xxl":  1400px,
  "xxxl": 1600px,
  "max":  1790px
);

@mixin mq($width, $type: min) {
  @if map-has-key($breakpoints, $width) {
    $width: map-get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
  }
  @media screen and (#{$type}-width: #{$width}) {
    @content;
  }
}
