.logo {
  display: flex;
  
  &__sign {
  	flex: 0 0 auto;
  	width: 26.3%;
  	height: 100%;
	}
  	
	&__text {
  	flex: 0 0 auto;
  	width: 73.7%;
  	height: 100%;
	}
}