.block {
  font-size: 1.8rem;
  line-height: 1.2;
  letter-spacing: .03em;
  background-color: var(--light-grey);
  border-radius: 16px;
  transition: box-shadow .2s;
  
  &:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,.2); 
  }
  
  &__container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  
  &__row {
    display: flex;
    justify-content: space-between;
    
    & + & {
      margin-top: 2rem;
    }
  }
  
  &__header {
    text-align: right;
    
    &_small {
      margin: 1rem 0 0;
      font-size: 1.6rem;
    }
  }
  
  &__title {
    font-size: 2.4rem;
    line-height: 1.08;
    letter-spacing: .03em;
    color: var(--black-blue);
  }
  
  &__icon {
    max-width: 7.5rem;
    max-height: 7.5rem;
  }
  
  .col & {
    height: 100%;
  }
  
  a {
    text-decoration: none;
  }
  
  a,
  a:hover {
    color: var(--dark);
  }
}