.blocks-slider {
  --blocks-slider-gap: 5rem;
  --bs-gutter-x: 1.5rem;
   
  margin: 5rem 0 0;

  @include mq('xl') {
    max-width: 1140px;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  
  @include mq('xxl') {
    max-width: 1320px;
  }
  
  .swiper-wrapper {
    
    @include mq('xl') {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: var(--blocks-slider-gap);
    }
  }
  
  .swiper-slide {
    max-width: 29rem;
    height: auto;
    padding-bottom: 12px;
    
    @include mq('xl') {
      max-width: none;
    }
    
    .block {
      height: 100%;
    }
  }
}